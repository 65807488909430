/* eslint-disable no-unused-vars */

import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
// Images
import bgImage from "assets/images/affabi-podium.svg";
import Rellax from "rellax";
import platformFlowImg from "../../../../assets/images/cs/CS-web-gifs/2.gif";

function HeaderHome() {
  const navigate = useNavigate();
  const typedJSRef = useRef(null);

  // Setting up typedJS
  // useEffect(() => {
  //   const typedJS = new Typed(typedJSRef.current, {
  //     strings: ["Data Submission", "Document Generation","Task Management", "Process Automation"],
  //     typeSpeed: 70,
  //     backSpeed: 30,
  //     backDelay: 900,
  //     startDelay: 500,
  //     loop: true,
  //   });
  //
  //   return () => typedJS.destroy();
  // }, []);

  return (
    <MKBox component="header"  height="100%" >

      <MKBox
        pt={6}
        display="flex"
        alignItems="center"
        // minHeight="100%"
        minHeight="85vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.0)
            )}, url(${bgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}

      >
        <Container>
          <Grid container spacing={3} sx={{ mt: 2 }} >
          <Grid item xs={12} md={6} lg={6}  >
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Stop Learning, Start Doing: Get Actionable Content That Drives Results
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              Affabi helps creators turn generic advice into actionable plans for their audiences—designed for their specific needs, not the masses.
            </MKTypography>
            <Stack direction="row" spacing={1} mt={3}>
              <MKButton variant="gradient" color="success" size={"large"} circular
                // onClick={()=>navigate('/contact-us')}
                        href={'https://app.affabi.com/business-signup'}
                        target="_blank"
                // rel="noreferrer"
              >
                Get Started

              </MKButton>

              <MKButton variant="text" color="secondary"  size={"medium"}
                        onClick={()=>navigate('/contact-us')}>
                contact to learn more &nbsp;
                <Icon fontSize="medium">arrow_right</Icon>
              </MKButton>

            </Stack>
          </Grid>
            <Grid item xs={12} md={6} lg={6} textAlign={"center"}>

              {/* <MKBox component="img" src={platformFlowImg} alt="platform" width="80%" /> */}
              {/* <MKBox */}
              {/*   sx={{ */}
              {/*     // position: "relative", */}
              {/*     width: "100%", */}
              {/*     // height: "100vh", */}
              {/*     overflow: "hidden", */}
              {/*   }} */}
              {/* > */}

              <video
                src={"/affabi-ContentPage-rearrange.webm"}
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              >
                {/* <source src="assets/images/aff-vdo/affabi-ContentPage-rearrange.webm" type="video/webm" /> */}
                Your browser does not support the video tag.
              </video>

              {/* </MKBox> */}
            </Grid>
          </Grid>

        </Container>
      </MKBox>
    </MKBox>
  );
}

export default HeaderHome;
