// @mui icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// MKit React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/app-brand/logo.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "AFFABI.CO",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/showcase/91738961",
    },
    // {
    //   icon: <TwitterIcon />,
    //   link: "https://twitter.com/creativetim",
    // },
    // {
    //   icon: <GitHubIcon />,
    //   link: "https://github.com/creativetimofficial",
    // },
    // {
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    // },
  ],

  menus: [
    {
      name: "Platform",
      items: [
        { name: "Overview", route: "/contact-us" },
        { name: "Dynamic Content", route: "/contact-us" },
        { name: "Page Builder", route: "/contact-us" },
        { name: "Dashboard Builder", route: "/contact-us" },
        { name: "Integrations", route: "/contact-us" },
        { name: "Artificial Intelligence", route: "/contact-us" },
        { name: "Data Conversion", route: "/contact-us" },
      ],
    },

    // {
    //   name: "Industries",
    //   items: [
    //     { name: "Banking & Financial Services", route: "/contact-us" },
    //     { name: "Insurance", route: "/contact-us" },
    //     { name: "Healthcare", route: "/contact-us" },
    //     { name: "Legal Services", route: "/contact-us" },
    //     { name: "Human Resources", route: "/contact-us" },
    //     { name: "Education", route: "/contact-us" },
    //     { name: "Real Estate", route: "/contact-us" },
    //     { name: "Government and Public Sector", route: "/contact-us" },
    //   ],
    // },

    {
      name: "Use cases",
      items: [
        { name: "Training", route: "/home" },
        { name: "Coaching", route: "/contact-us" },
        { name: "Customer Portal", route: "/contact-us" },
        { name: "Process Documentation", route: "/contact-us" },
        { name: "Report", route: "/contact-us" },
        { name: "Private Portal", route: "/contact-us" },
        { name: "Content Migration", route: "/contact-us" },
      ],
    },
    // {
    //   name: "company",
    //   items: [
    //     { name: "about us", route: "/about-us" },
    //     { name: "Contact us", route: "/contact-us" },
    //     // { name: "about Ace|Oar", href: "https://www.aceoar.io/about-us" },
    //     // { name: "blog", href: "https://www.affabi.co/blog" },
    //   ],
    // },
    // {
    //   name: "resources",
    //   items: [
    //     { name: "illustrations", href: "https://iradesign.io/" },
    //     { name: "bits & snippets", href: "https://www.affabi.co/bits" },
    //     { name: "affiliate program", href: "https://www.affabi.co/affiliates/new" },
    //   ],
    // },
    {
      name: "help & support",
      items: [
        { name: "contact us", route: "/contact-us"  },
        { name: "knowledge center", route: "/contact-us"  },
        { name: "custom development", route: "/contact-us"  },
        { name: "Partners", route: "/contact-us"  },
        { name: "Investors", route: "/contact-us"  },
        { name: "Integration Partners", route: "/contact-us"  },
      ],
    },
    // {
    //   name: "legal",
    //   items: [
    //     { name: "terms & conditions", href: "https://www.affabi.co/terms" },
    //     { name: "privacy policy", href: "https://www.affabi.co/privacy" },
    //     { name: "licenses (EULA)", href: "https://www.affabi.co/license" },
    //   ],
    // },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} Affabi.co by{" "}
      <MKTypography
        component="a"
        href="https://www.aceoar.io"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Ace|Oar Technologies, Inc
      </MKTypography>
      . All rights reserved.
    </MKTypography>
  ),
};
