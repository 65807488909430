
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/affabi-sources.webp";
function FastToMarket() {


  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            We're On a Mission:
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
            Transform How You Impact Today
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            By creating scenario-specific actionable content, Affabi let you significantly enhance the relevance and impact of your work.
            This approach not only meets the growing demand for personalized learning but also fosters deeper connections between you and your audiences.
            {/* Embracing this change requires a commitment to understanding individual needs and continually refining content to ensure it is as actionable and beneficial as possible. */}

            {/* Affabi is the ultimate platform for creating stunning, customizable pages in minutes. */}
            {/* With intuitive drag-and-drop widgets—like text, images, videos, and graphs—you can design pages that fit your business needs perfectly. */}
            {/* Effortlessly connect to external data sources for real-time content updates */}
            {/* and use AI-powered* tools to generate content. */}

          </MKTypography>



          {/* <MKTypography */}
          {/*   component="a" */}
          {/*   href="https://www.creative-tim.com/learning-lab/react/overview/material-kit/" */}
          {/*   target="_blank" */}
          {/*   rel="noreferrer" */}
          {/*   variant="body2" */}
          {/*   color="white" */}
          {/*   fontWeight="regular" */}
          {/*   sx={{ */}
          {/*     display: "flex", */}
          {/*     alignItems: "center", */}

          {/*     "& .material-icons-round": { */}
          {/*       fontSize: "1.125rem", */}
          {/*       transform: `translateX(3px)`, */}
          {/*       transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)", */}
          {/*     }, */}

          {/*     "&:hover .material-icons-round, &:focus .material-icons-round": { */}
          {/*       transform: `translateX(6px)`, */}
          {/*     }, */}
          {/*   }} */}
          {/* > */}
          {/*   Read docs <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
          {/* </MKTypography> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FastToMarket;
