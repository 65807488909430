
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "../components/AboutUsOption";
import valProp from "assets/images/cs/sqr-imgs/API.png";

function Integrations() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} lg={5} sx={{ textAlign: { xs: "center" } }}>

            <MKTypography variant="h3" mb={3}>
              Integration Options
            </MKTypography>
            <MKBox  component="img" src={valProp} alt="platform" width={"50%"} />
            <MKTypography variant="body2" color="text" my={2}>
              Grow without constraints. Affabi supports various integrations through

              custom connectors to meet
              your evolving business needs seamlessly.
            </MKTypography>
            {/* <MKTypography */}
            {/*   component="a" */}
            {/*   href="#" */}
            {/*   variant="button" */}
            {/*   color="info" */}
            {/*   fontWeight="regular" */}
            {/*   sx={{ */}
            {/*     width: "max-content", */}
            {/*     display: "flex", */}
            {/*     alignItems: "center", */}

            {/*     "& .material-icons-round": { */}
            {/*       fontSize: "1.125rem", */}
            {/*       transform: "translateX(3px)", */}
            {/*       transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)", */}
            {/*     }, */}

            {/*     "&:hover .material-icons-round, &:focus .material-icons-round": { */}
            {/*       transform: "translateX(6px)", */}
            {/*     }, */}
            {/*   }} */}
            {/* > */}
            {/*   More about us */}
            {/*   <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
            {/* </MKTypography> */}
          </Grid>
          {/* <Grid item xs={12} lg={7} sx={{ textAlign: { xs: "center" } }}> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={6} alignItems={"right"}> */}
          {/*   /!* <MKBox  component="img" src={valProp} alt="platform" width={"50%"} /> *!/ */}
          {/* </Grid> */}
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 8 } }}>
            {/*  */}

            <Stack>
              <AboutUsOption
                icon="mediation"
                content={
                  <>
                    Collect data from various sources including your
                    <br/>
                    own systems to unify, enrich or validate.
                  </>
                }
              />
              <AboutUsOption
                icon="settings_overscan"
                content={
                  <>
                    Get converted data in your desired format to ingest
                    <br/>
                    by your systems.
                  </>
                }
              />
              <AboutUsOption
                icon="token"
                content={
                  <>
                    Use built-in connectors to access private / public
                    <br/>
                    API sources.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Integrations;
