
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "../../../components/MKTypography";
import FilledInfoCard from "../../../examples/Cards/InfoCards/FilledInfoCard";
import platformFlowImg from "../../../assets/images/affabi-podium.svg";

function WhatIsAffabi() {
  return (
    <MKBox component="section" py={1} mt={1}>
      <Container sx={{ mt: 12 }}>
        {/* <MKBox component="section" py={3} mt={8}> */}
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h2" fontWeight="bold" mb={0.5} color={"success"}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["3xl"]
                            }
                          })}
            >
              Make Your Process Valuable—and Profitable
            </MKTypography>
            <MKTypography variant="d6" color="text" sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["2xl"]
              }
            })}>
              Affabi isn’t just about creating pages; it’s about creating impact. Help your audience solve their problems, take meaningful action, and see results—all while building trust and growing your revenue. Monetize through consulting, tailored templates, or interactive services directly on the platform.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }}>
            <MKBox component="img" src={platformFlowImg} alt="platform" width="80%" mt={-6} />
          </Grid>
        </Grid>
        {/* </MKBox> */}
        {/* <SampleWorkflow /> */}
      </Container>
    </MKBox>
  );
}

export default WhatIsAffabi;
