
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "../../../components/MKTypography";
import FilledInfoCard from "../../../examples/Cards/InfoCards/FilledInfoCard";

function Purpose() {
  return (
    <MKBox component="section" py={12} mt={8}>
      <Container>
        <Grid container spacing={3} justifyContent={"center"} mt={8}>
          <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h3" fontWeight="bold" mb={0.5} color={"success"}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["2xl"]
                            }
                          })}
            >
              Affabi Is A Framework Tool
            </MKTypography>
            {/* <MKTypography variant="body1" color="text"> */}
            {/*   Your Digital Transformation initiatives doesn't have to halt because of IT backlogs anymore. Your non-technical business users can now */}
            {/*   build your application intake process. */}
            {/* </MKTypography> */}
          </Grid>
        </Grid>

        {/* <MKTypography variant="d4" color="text"> */}
        {/*   68% of consumers abandoned an application because of frictions */}
        {/*   /!* Your Digital Transformation initiatives doesn't have to halt because of IT backlogs anymore. Your non-technical business users can now *!/ */}
        {/*   /!* build your application intake process. *!/ */}

        {/* </MKTypography> */}



        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h2" fontWeight="bold" mb={0.5} color={"info"}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["2xl"]
                            }
                          })}
            >
              {/* Give your teams their lunch break back. */}
              {/* Gain Customer Confidence Delivering a Transparent Experience */}
              Empowering Creators to Deliver Actionable, Personalized Content
            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={10} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="body1" color="text">
              {/* Replace multiple broken tools with AFFABI, the only platform designed to make your team faster—and happier. */}
              {/* Affabi minimizes frictions and deliver seamless experience to both customers and employees. */}
              Affabi lets you build dynamic, interactive pages packed with customizable widgets—text, videos, graphs, and more. But it’s more than just a page builder.
            </MKTypography>

          </Grid>

          {/* <Grid item xs={12} lg={12}> */}
          {/* </Grid> */}

          <Grid item xs={12} lg={10} sx={{ textAlign: { xs: "center" } }}>
            <MKTypography variant="h3" fontWeight="bold" mt={6} mb={1} color={"light"}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["2xl"]
                            }
                          })}
            >
              With Affabi, you can:
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FilledInfoCard
              // variant="contained"
              color="success"
              icon="person"
              title="Tailor Content to Specific Scenarios"
              description="Use audience input to deliver solutions designed just for them."
              // action={{
              //   type: "external",
              //   route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
              //   label: "Let's start"
              // }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FilledInfoCard
              // variant="gradient"
              color="error"
              icon="watch"
              title="Leverage AI to Save Time"
              description="Quickly generate content ideas and templates for personalized guidance."
              // action={{
              //   type: "external",
              //   route: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/",
              //   label: "Read more"
              // }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FilledInfoCard
              // variant="info"
              color="info"
              icon="bolt"
              title="Streamline Action Plans"
              description="Guide your audience step by step, ensuring they take the right actions to achieve their goals."
              // action={{
              //   type: "external",
              //   route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
              //   label: "Read more"
              // }}
            />
          </Grid>
          {/* <Grid item xs={12} lg={12} mt={6} sx={{ textAlign: { xs: "center" } }}> */}
          {/*   <MKTypography variant="h5" fontWeight="regular" mb={0.5} */}
          {/*                 sx={({ breakpoints, typography: { size } }) => ({ */}
          {/*                   [breakpoints.down("md")]: { */}
          {/*                     fontSize: size["2xl"] */}
          {/*                   } */}
          {/*                 })} */}
          {/*   > */}
          {/*     And above all */}
          {/*   </MKTypography> */}
          {/*   <MKTypography variant="h3" fontWeight="bold" mb={0.5} */}
          {/*                 sx={({ breakpoints, typography: { size } }) => ({ */}
          {/*                   [breakpoints.down("md")]: { */}
          {/*                     fontSize: size["2xl"] */}
          {/*                   } */}
          {/*                 })} */}
          {/*   > */}
          {/*     Affabi strive to Minimize Risk */}
          {/*   </MKTypography> */}

          {/* </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Purpose;
