import { useNavigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import step1Img from "assets/images/cs/cs-steps/1.png";
import step2mg from "assets/images/cs/cs-steps/2.png";
import step3Img from "assets/images/cs/cs-steps/3.png";

import urWeb1Img from "assets/images/cs/CS-screens/16.png";
import cs2img from "assets/images/app-brand/logo.svg";
import urSys3Img from "assets/images/cs/cs-steps/3.png";
import platformOrchestrationImg from "assets/images/cs/CS-screens/9.png";
function WhatYouCanDo() {
  const navigate = useNavigate();
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }}>
      <Container>
        <Grid container alignItems="center" justifyContent={{ xs: "center", md: "start" }}>
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h2" mb={1}>
              What You Can Do with AFFABI?
            </MKTypography>
            {/* <MKTypography variant="body2" mb={2}> */}
            {/*   AFFABI offers businesses a suite of tools, including Form Builder, Integrations for data/ID verification, and case and task management, to streamline customer information intake and control the customer experience. */}
            {/* </MKTypography> */}
          </Grid>
          <Grid item xs={12} lg={6} textAlign="right">
            <MKButton variant="gradient" color="warning"
                      onClick={()=>navigate('/contact-us')}>
              Contact Us
            </MKButton>
          </Grid>
          <Grid item xs={12} lg={10} sx={{ textAlign: { xs: "center" } }} alignItems="center" >

            <MKBox component="img" src={platformOrchestrationImg} alt="platform" width="80%" />
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={6}>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"grid_view"}
              title="Application Development"
              description="Modernize, customize, automate, and integrate enterprise-grade systems"
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"account_tree"}
              title="Process Automation"
              description="List and configure your offerings so that Affabi knows what to request from customers. Publish, and you are ready for submissions!"
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"hub"}
              title="Data Exchange"
              description="Exchange data with internal and external systems to unify, enrich and verify information"
              small
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={"handshake"} */}
          {/*     title="Affabit Management" */}
          {/*     description="Design your intake application - forms, file requirements to capture customer information seamlessly with our user-friendly form design tools." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"task_alt"}
              title="Case Management"
              description="Handle submissions on quick and powerful employee / agent dashboard"
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"computer"}
              title="Legacy Modernization"
              description="Transform legacy systems into cross-platform usability hub"
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"person_check"}
              title="Non-tech Development"
              description="Empower business users to be able to build and roll-out intake processes without help from IT."
              small
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhatYouCanDo;
