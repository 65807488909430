import React, { useEffect } from "react";

import { useLocation,useParams, Link } from "react-router-dom";

import PropTypes from "prop-types";
import { Helmet } from 'react-helmet'

import MKTypography from "../../components/MKTypography";
import MKBadge from "../../components/MKBadge";
import MKButton from "../../components/MKButton";
import PublicFooter from "./PublicFooter";
import MKBox from "../../components/MKBox";
import routes from "../../routes";
import siteRoutes from "../../site-routes";
import DefaultNavbar from "../DefaultNavbar";
import DefaultFooter from "../DefaultFooter";
import footerRoutes from "../../footer.routes";
import pageRoutes from "../../page-routes";

function PublicLayout({ children, title , background}) {
  const { pathname } = useLocation();
  const { tenantName } = useParams();



  // useEffect(() => {
  //   setLayout(dispatch, "public");
  // }, [pathname]);

  return (
    <MKBox
      // width="98vw"
      // height="100%"
      minHeight="80vh"
      bgColor={background}
      // sx={{ overflowX: "hidden" }}
    >
      <Helmet>
        <title>
          AFFABI - {title || "Application Workflow Platform"}
        </title>
      </Helmet>

      <DefaultNavbar
        routes={pageRoutes}
        // routes={[]}
        action={{
          type: "external",
          route: "https://app.affabi.com/provider-lookup",
          label: "User Login",
          color: "primary",
        }}
        transparent
        light
        // sticky
      />
      {children}

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

    </MKBox>
  );
}

// Setting default values for the props for PublicLayout
PublicLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PublicLayout
PublicLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export const withPublicLayout = (component: React.ComponentType): React.FunctionComponent<{}> => {
  return function () {
    return <PublicLayout>{React.createElement(component)}</PublicLayout>;
  };
};

export default PublicLayout;
