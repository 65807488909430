
// @mui material components
// import Card from "@mui/material/Card";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Pricing page sections
import Header from "./sections/Header";
import AboutUs from "./sections/AboutUs";
import PricingSection from "./sections/Pricing";
import LifetimeMembership from "./sections/LifetimeMembership";
// import Testimonials from "./sections/Testimonials";
import Trust from "./sections/Trust";
import Faq from "./sections/Faq";

// Routes
import routes from "site-routes";
import footerRoutes from "footer.routes";
import PublicLayout from "../../layouts/PublicLayout";

function Pricing() {
  return (
    <PublicLayout title={"Digital Application Intake & Workflow Solution"}>
      {/* <DefaultNavbar */}
      {/*   routes={routes} */}
      {/*   action={{ */}
      {/*     type: "external", */}
      {/*     route: "https://www.creative-tim.com/product/material-kit-pro-react", */}
      {/*     label: "buy now", */}
      {/*   }} */}
      {/*   transparent */}
      {/*   light */}
      {/* /> */}
      {/* <Header /> */}
      <MKBox p={3}>
        {/* <AboutUs /> */}
        <PricingSection />
        <LifetimeMembership />
        {/* <Testimonials /> */}
        <Trust />
        <Faq />
      </MKBox>
      {/* <MKBox pt={6} px={1} mt={6}> */}
      {/*   <DefaultFooter content={footerRoutes} /> */}
      {/* </MKBox> */}
    </PublicLayout>
  );
}

export default Pricing;
