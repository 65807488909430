
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// MKit React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// MKit React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";

function Pricing() {
  return (
    <>
      <MKBox position="relative" variant="contained"  mt={{ xs: 0, lg: 12 }} mx={-2}>
        {/* <MKBox */}
        {/*   component="img" */}
        {/*   src={bgPattern} */}
        {/*   alt="background-pattern" */}
        {/*   position="absolute" */}
        {/*   top={0} */}
        {/*   left={0} */}
        {/*   width={{ xs: "auto", lg: "100%" }} */}
        {/*   height={{ xs: "100%", lg: "auto" }} */}
        {/*   opacity={0.6} */}
        {/* /> */}
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKBadge badgeContent="pricing" color="dark" size="sm" container sx={{ mb: 1 }} />
              <MKTypography variant="h3" color="white" mb={1}>
                Transform How You Create Impact Today
              </MKTypography>
              <MKTypography variant="body2" color="white">
                You will have direct access to our team who built the product for any support or customization.
              </MKTypography>
              {/* <MKTypography variant="h5" color="success" my={2}> */}
              {/*   /!* Prices below are monthly, charged yearly. *!/ */}
              {/*   We're just starting. Try Affabi and let us know if you like it! */}
              {/* </MKTypography> */}
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox mt={-19}>
        <Container>
          <Grid container spacing={3} sx={{ mt: 6 }}>
            <Grid item xs={12} sm={6} lg={4}>
              <SimplePricingCard
                variant="gradient"
                color="dark"
                title="Standard"
                description="Good for an Individuals / Small Team."
                price={{ value: "MVP Stage: Free Trial" }}
                action={{ type: "external", route: "https://app.affabi.com/business-signup", label: "Signup" }}
                specifications={[
                  "Branded Landing (Logo, Messaging)",
                  "Custom subdomain (e.g. you.affabi.com)",
                  "Create Unlimited Offerings",
                  // "AI Assisted Suggestive Outlines",
                  "Static Data Sources (csv, pdf)",
                  "10GB Storage (Data, Media)",
                  "Upto 5 Admin/Editor Users",
                  "Upto 1,000 Active Subscribers",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SimplePricingCard
                variant="gradient"
                color="info"
                title="Premium"
                description="Build your startup or client web/mobile app."
                price={{ value: "COMING SOON" }}
                action={{ type: "internal", route: "/", label: "COMING SOON", disabled:true }}
                specifications={[
                  "Everything in Standard Plan + ",
                  "Your own domain (e.g. learn.your-domain.com)",
                  "Payment Processing (e.g. Stripe)",
                  "Unlimited Admin/Editor Users",
                  "100GB Storage",
                  "AI Assistance (e.g. For Quiz, Summarize etc.) ",
                  "Dynamic Data Sources (e.g. API, Google Sheet etc.)",
                  "Upto 10,000 Active Subscribers",
                  "General Newsletter Campaigns",
                  "Subscriber Analytics, Task & Progress Tracking",
                  "User Experience Modules (Basic Chat, Feed)",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SimplePricingCard
                variant="gradient"
                color="secondary"
                title="Enterprise"
                description="Perfect for web/mobile apps or SaaS projects."
                price={{ value: "Contact Us" }}
                action={{ type: "internal", route: "/", label: "COMING SOON", disabled:true}}
                specifications={[
                  "Everything in Premium Plan + ",
                  "SSO Integration / Custom ID Pool / Azure AD",
                  "Isolated Data Storage with AZ Support",
                  "On-Demand File Storage",
                  "Custom AI / LLM Assistance*",
                  "Custom Data Sources (Internal / External)",
                  "Custom Campaigns",
                  "Advanced Analytics and reports",
                  "Community Space (Micro learning, Feeds)",
                  "Unlimited Subscribers",
                ]}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={3}> */}
            {/*   <SimplePricingCard */}
            {/*     color="dark" */}
            {/*     title="Enterprise" */}
            {/*     description="Deploy large-scale projects which include redistribution rights." */}
            {/*     price={{ value: "$599" }} */}
            {/*     action={{ type: "internal", route: "/", label: "buy now" }} */}
            {/*     specifications={[ */}
            {/*       "Complete documentation", */}
            {/*       "Full code", */}
            {/*       "Use in SaaS", */}
            {/*       "Projects - unlimited", */}
            {/*       "Team Size - more than 20", */}
            {/*       "Priority support", */}
            {/*       "Free Updates - 24 months", */}
            {/*     ]} */}
            {/*   /> */}
            {/* </Grid> */}
          </Grid>
          <Grid container item xs={12} flexDirection="column" sx={{ textAlign: "center" }}>
            <MKTypography variant="body2" color="text" mt={6} mb={3} mx="auto">
              <Icon>lock</Icon> Secured Payment by <b>Stripe</b>
              {/* with: */}
            </MKTypography>
            {/* <MKTypography variant="body2" color="text" mb={2} mx="auto"> */}
            {/*   <i className="fa fa-cc-paypal fa-2x" /> */}
            {/*   &nbsp; */}
            {/*   <i className="fa fa-cc-visa fa-2x" /> */}
            {/*   &nbsp; */}
            {/*   <i className="fa fa-cc-mastercard fa-2x" /> */}
            {/*   &nbsp; */}
            {/*   <i className="fa fa-cc-amex fa-2x" /> */}
            {/* </MKTypography> */}
            {/* <MKTypography variant="body2" color="text" mx="auto" sx={{ maxWidth: "500px" }}> */}
            {/*   <b>Info:</b> If you are a Registered Company inside the European Union you will be */}
            {/*   able to add your VAT ID after your Press &quot;Buy Now&quot; */}
            {/* </MKTypography> */}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Pricing;
