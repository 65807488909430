
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import step1Img from "assets/images/cs/cs-steps/1.png";
import step2mg from "assets/images/cs/cs-steps/2.png";
import step3Img from "assets/images/cs/cs-steps/3.png";
import step4Img from "assets/images/cs/cs-steps/4.png";
import step5Img from "assets/images/cs/cs-steps/5.png";
import step6Img from "assets/images/cs/cs-steps/6.png";

import urWeb1Img from "assets/images/cs/CS-screens/16.png";
import cs2img from "assets/images/app-brand/logo.svg";
import urSys3Img from "assets/images/cs/cs-steps/3.png";

function WhyChoose() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 18 }}>

      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={12}>
            <MKTypography variant="h2" mb={1}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["3xl"],
                            },
                          })}
            >
              What Makes Affabi Different?
            </MKTypography>
            {/* <MKTypography variant="body2" mb={2}> */}
            {/*   AFFABI offers businesses a suite of tools, including Form Builder, Integrations for data/ID verification, and case and task management, to streamline customer information intake and control the customer experience. */}
            {/* </MKTypography> */}
          </Grid>
          {/* <Grid item xs={12} lg={6} textAlign="right"> */}
          {/*   <MKButton variant="gradient" color="warning"> */}
          {/*     Contact Us */}
          {/*   </MKButton> */}
          {/* </Grid> */}

        </Grid>

        <Grid container spacing={3} mt={6}>

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              // icon={"search"}

              title="Customizable Widgets"
              description="Build content-rich, interactive pages your audience can engage with."
              // small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              // icon={<MKBox component="img" src={step2mg} alt="Define Workflow / Process" width="50%" />}

              title="Scenario-Based Inputs"
              description="Collect audience-specific details to deliver actionable plans."
              // small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              // icon={<MKBox component="img" src={step3Img} alt="Configure Offerings" width="50%" />}
              title="AI-Powered Content Generation"
              description="Save time by creating tailored solutions faster."
              // small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              // icon={<MKBox component="img" src={step4Img} alt="Application Start" width="50%" />}
              title="Dynamic Data Integration"
              description="Keep content updated in real-time with external data sources."
              // small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              // icon={<MKBox component="img" src={step5Img} alt="Submission Review" width="50%" />}
              title="Secure & Scalable Hosting"
              description="Choose from shared or dedicated backends based on your growth."
              // small
            />
          </Grid>

        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyChoose;
